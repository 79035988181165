<template>
  <div class="login_container">
    <div class="login_box">
      <!--头像区域 -->
      <div class="avator-box">
        <img src="../assets/images/logo.png">
      </div>
      <!-- 登录区域 -->
      <el-form label-width="100px" class="login_form" :model="loginForm" :rules="registFormRules" ref="registerFromRef" label-position="top">
        <!-- 用户名 -->
        <el-form-item prop="username" label="请输入用户名">
          <el-input v-model="loginForm.username" prefix-icon="iconfont icon-user" />
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password" label="请输入密码">
          <el-input v-model="loginForm.password" prefix-icon="iconfont icon-lock" type="password" />
        </el-form-item>
        <el-form-item prop="password" label="请再次输入密码">
          <el-input v-model="loginForm.password" prefix-icon="iconfont icon-lock" type="password" />
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" @click="login">
            注册
          </el-button>

        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'register',
  data() {
    return {
      loginForm: {
        username: 'admin',
        password: 123456
      },
      //表单验证规则对象
      registFormRules: {
        //验证用户名
        username: [{
          required: true,
          message: '请输入活动名称',
          trigger: 'blur'
        },
          {
            min: 3,
            max: 10,
            message: '长度在 3 到 10 个字符',
            trigger: 'blur'
          }
        ],
        //验证密码
        password: [{
          required: true,
          message: '请输入登录密码',
          trigger: 'blur'
        },
          {
            min: 6,
            max: 15,
            message: '长度在 6 到 15 个字符',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    //按钮重置事件
    resetloginForm() {
      this.$refs.loginFromRef.resetFields()
    },
    //登录事件
    login() {
      // this.$refs.loginFromRef.validate(async valid => {
      //   if (!valid) return
      //   const {
      //     data:res
      //   } = await this.$http.post("login", this.loginForm)
      //   console.log(res.data.token)
      //   if (res.meta.status !== 200) {
      //     this.loginerror()
      //   } else {
      //     this.loginsuccess()
      //     //1.将token保存在sessionlocalstorage中
      //     //除了登录之外的其他api接口必须在登录之后才能访问
      //     //1.2token只应该在网站打开期间生效,所以将token保存在sessionSTORAHE中
      //     window.sessionStorage.setItem('token',res.data.token)
      //     this.$router.push('/home')
      //   }
      // })
      console.log('执行登录')
      this.$router.push('/home')
    },
    //登录状态提示框
    loginsuccess() {
      this.$message({
        message: '登录成功',
        type: 'success'
      });
    },
    loginerror() {
      this.$message.error('登录失败');
    }
  }
}
</script>

<style scoped>
.login_container {
  background-color: #6699CC;
  height: 100%;
}

.login_box {
  width: 450px;
  background-color: #FFFFFF;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

.avator-box {
  height: 130px;
  width: 130px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;

}

.avator-box img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #eee;
}

.btns {
  display: flex;
  justify-content: flex-end;
}

.login_form {
  margin-top: 50px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
</style>
